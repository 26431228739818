<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select class="filter-item" v-model="query.entId" :label.sync="query.entName" filterable value-field="enterpriseId" url="api/distributor" :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }" placeholder="请选择经销商" @change="toQuery" style="width: 230px;"  clearable>
                <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
              </quick-select>
      <quick-select v-if="query.entId" url="api/warehouse" v-model="query.warehouseIds" :params="{entId:query.entId}" multiple filterable collapse-tags placeholder="仓库" class="filter-item" @change="toQuery" clearable style="width: 240px;" />
      <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 160px;" />
      <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 160px;" />
      <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 160px;" />
      <!-- <el-input-number class="filter-item" v-model="query.minCount" :precision="0" :min="0" :controls="false" placeholder="最小库存" style="width: 100px;" />
      <el-input-number class="filter-item" v-model="query.maxCount" :precision="0" :min="0"  :controls="false" placeholder="最大库存" style="width: 100px;" /> -->
      <!-- <el-input class="filter-item" type="number" v-model.number="query.minCount" min="0" placeholder="最小库存" style="width: 100px;" />
      <el-input class="filter-item" type="number" v-model.number="query.maxCount" min="0" placeholder="最大库存" style="width: 100px;" /> -->
      <el-input v-model="query.name" :maxlength="20" clearable placeholder="输入商品名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.code" :maxlength="20" clearable placeholder="输入商品编码搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <!-- <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button> -->
      <el-button type="info" @click="upload" class="filter-item" icon="el-icon-upload2" >导入库房&库存</el-button>
      <el-button type="warning" @click="clear" class="filter-item" icon="el-icon-error" >清除库存</el-button>
    </div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="code" label="商品编码" min-width="130" fixed/>
      <el-table-column prop="erpCode" label="ERP编码" min-width="130" fixed/>
      <el-table-column prop="name" label="商品名称" min-width="240px" fixed/>
      <el-table-column prop="specs" label="规格" min-width="160" :formatter="$goodsSpecConvert" fixed/>
      
      <el-table-column prop="countInfo.warehouseCount" label="库存" width="120px" align="right" />
      <!-- <el-table-column prop="countInfo.saleCount" label="待发货数量" width="120px" align="right" />
      <el-table-column prop="countInfo.purchaseCount" label="在途数量" width="120px" align="right" />
      <el-table-column label="可用数量" width="120px" align="right">
        <b class="fc-s" slot-scope="scope">{{scope.row.countInfo.warehouseCount + scope.row.countInfo.purchaseCount - scope.row.countInfo.saleCount}}</b>
      </el-table-column> -->
      <el-table-column prop="brandName" label="品牌" width="120px" />
      <el-table-column prop="categoryName" label="分类" width="120px" />
      <el-table-column prop="seriesName" label="系列" width="120px" />
      <!-- <el-table-column width="80px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button type="text" size="mini" @click="detail(scope.row)">库存明细</el-button>
        </div>
      </el-table-column> -->
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <importForm ref = "importForm"/>
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import importForm from "./import-detail"
import request from "@/utils/request";

export default {
  components: { importForm },
  mixins: [initData],
  data() {
    return {
      downloadLoading: false,
      query:{
        warehouseIds:null,
        brandId:null,
        seriesId:null,
        categoryId:null,
        minCount:null,
        maxCount:null,
        name:null,
        code:null,
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/warehouseGoods";
      this.params = this.query;
      return true;
    },
    upload(){
      if(!this.query.entId) {
        this.$message.warning("请选择经销商");
        return;
      }
      this.$refs.importForm && this.$refs.importForm.resetForm(this.query.entId,this.query.entName);
    },
    clear(){
      if(!this.query.entId) {
        this.$message.warning("请选择经销商");
        return;
      }
      this.$confirm(
        "您确定要清除该经销商"+(this.query.warehouseIds&&this.query.warehouseIds.length?"【所选择仓库】下的":"【所有】")+"库存数据吗？ 此操作不可恢复",
        "操作确认",
        { type: "warning", dangerouslyUseHTMLString: true }
      ).then(_ => {
        request({
            url: 'api/stock/clear/'+this.query.entId,
            data:this.query.warehouseIds,
            method: "delete"
        }).then(_=>{
          this.init();
            this.$message({
              type: "success",
              message: "清除成功!"
            });
        }).catch(err=>{
          this.$message.error("清除失败,请稍后重试");
        });
      });
    }
  }
};
</script>